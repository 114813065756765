<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Educational Qualification") }}</div>
            <button class="button danger-button" @click.prevent="backToEducationList">{{ $t("Cancel") }}</button>
        </div>
        <div class="details__card" v-if="!isContentLoading">
            <form>
                <div class="details__card__head">
                    <h4 class="text-capitalize">{{ $t("academic qualification") }}</h4>
                </div>
                <div class="details__card__body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t("Level of Education") }}*</label>
                                <vue-autosuggest
                                        ref="autosuggest"
                                        v-model="form.level"
                                        @click="levelClickHandler"
                                        @selected="levelSelectHandler"
                                        @input="levelInputHandler"
                                        :suggestions="filteredLevelSuggestions"
                                        :inputProps="{id: 'level', placeholder: $t('Enter level of education'), class: 'form-control' }"
                                >
                                    <template slot-scope="{ suggestion }">
                                        <b>{{ suggestion.item }}</b>
                                    </template>
                                </vue-autosuggest>
                                <error-message :message="errors.level"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="academy_name">{{ $t("Institute name") }}*</label>
                                <input id="academy_name" type="text" v-model="form.academy_name" class="form-control"
                                       :placeholder="$t('enter institute name')"/>
                                <error-message :message="errors.academy_name"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="passing_year">{{ $t("Year of Passing") }}*</label>
                                <input id="passing_year"
                                       type="text"
                                       class="form-control"
                                       v-model="form.passing_year"
                                       :placeholder="$t('Enter year of passing')"
                                       maxlength="4"
                                       @keypress="isNumber($event)"
                                />
                                <error-message :message="errors.passing_year"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t("Exam or Degree Title") }}*</label>
                                <vue-autosuggest
                                        ref="autosuggest"
                                        v-model="form.degree"
                                        @click="degreeClickHandler"
                                        @input="degreeInputHandler"
                                        @selected="degreeSelectHandler"
                                        :suggestions="filteredDegreeSuggestions"
                                        :inputProps="{id: 'level', placeholder: $t('Enter degree'), class: 'form-control' }"
                                >
                                    <template slot-scope="{ suggestion }">
                                        <b>{{ suggestion.item }}</b>
                                    </template>
                                </vue-autosuggest>
                                <error-message :message="errors.degree"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details__card__footer">
                    <submit-button :click="submitCandidateEducation" :loading="isLoading" type="primary-button ml-auto">
                        {{ $t("Save") }}
                    </submit-button>
                </div>
            </form>
        </div>

    </div>
</template>

<script>

import ErrorMessage from "../../../../components/common/ErrorMessage";
import Multiselect from "vue-multiselect";

import {
    getCandidateEducation,
    searchEducationDegree,
    getEducationLevel, getCategory,
} from "../../../../app/api/CommonRequest";
import client from "../../../../app/api/Client";
import {VueAutosuggest} from 'vue-autosuggest';
import {debounce} from "lodash";

export default {
    name: "Form",
    data() {
        return {
            isContentLoading: false,
            isLoading: false,
            form: {
                id: null,
                level: '',
                degree: '',
                passing_year: null,
                academy_name: '',
            },
            levels: [],
            degrees: [],
            errors: {}

        }
    },
    components: {
        ErrorMessage,
        Multiselect,
        VueAutosuggest
    },
    computed: {
        filteredDegreeSuggestions() {
            if (this.degrees.length > 0) {
                if (!this.form.degree) {
                    return [{data: this.degrees}];
                }

                return [{
                    data: this.degrees.filter(item => {
                        return (item.toLowerCase().indexOf(this.form.degree.toLowerCase()) > -1);
                    })
                }];
            }
            return [];
        },
        filteredLevelSuggestions() {
            if (this.levels.length > 0) {
                return [{data: this.levels}];
            }
            return [];
        },
    },
    methods: {
        degreeSelectHandler(item) {
            if (item) {
                this.form.degree = item.item;
            }
        },
        levelSelectHandler(item) {
            if (item) {
                this.form.level = item.item;
            }
        },

        levelInputHandler: debounce(function (item) {
            getEducationLevel(item).then((data) => {
                this.levels = data.map(level => level.name);
            });
        }, 1000),
        degreeInputHandler: debounce(function (item) {
            searchEducationDegree(item).then((data) => {
                this.degrees = data.map(degree => degree.name);
            });
        }, 1000),

        async levelClickHandler() {
            let data = await getEducationLevel();
            this.levels = data.map(level => level.name);
        },
        async degreeClickHandler() {
            let data = await searchEducationDegree();
            this.degrees = data.map(degree => degree.name);
        },

        async getEducation(id) {
            this.isContentLoading = true;
            try {
                let {data} = await getCandidateEducation(id);
                this.form = data;
                if (data.degree) {
                    this.form.degree.name = this.$t(data.degree.name)
                }
                if (data.level) {
                    this.form.level.name = this.$t(data.level.name)
                }
            } catch (e) {
            }

            this.isContentLoading = false;
        },
        backToEducationList() {
            this.$router.push({name: 'candidate.qualifications'})
        },

        async submitCandidateEducation() {
            this.errors = {};
            if (!this.validate()) {
                return false;
            }
            this.isLoading = true;
            try {
                let {data} = await client().post('/candidate/education', this.form);
                this.$toast.success(this.$t(data.message));
                this.backToEducationList();
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        validate() {
            let isValid = true;

            if (!this.form.level) {
                this.errors.level = this.$t("Please select level of education.");
                isValid = false;
            }

            if (!this.form.academy_name) {
                this.errors.academy_name = this.$t("Please input institute name.");
                isValid = false;
            } else if (this.form.academy_name.length > 100) {
                this.errors.academy_name = this.$t("Please input institute name within 100 characters.");
                isValid = false;
            }

            if (!this.form.passing_year) {
                this.errors.passing_year = this.$t("Please input year of passing.");
                isValid = false;
            } else if (!parseInt((this.form.passing_year))) {
                this.errors.passing_year = this.$t("Please select valid year of passing.");
                isValid = false;
            } else if (this.form.passing_year > new Date().getFullYear()) {
                this.errors.passing_year = this.$t("Please select valid year of passing.");
                isValid = false;
            }

            if (!this.form.degree) {
                this.errors.degree = this.$t("Please select exam or degree title.");
                isValid = false;
            }

            return isValid;
        },
    },
    mounted() {
        if (this.$route.params.id) {
            this.getEducation(this.$route.params.id);
        }
    }

}
</script>
<style>
#autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    border: 1px solid #616161;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 320px;
    overflow: auto;
}

.autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 10px 12px;
    border-bottom: 1px solid #e0e0e0;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
}

.autosuggest__results .autosuggest__results_title {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #F6F6F6;
}

</style>
